.helpline {
  background-color: #d4e9e2;
  padding: 20px;
}

.helpline-give-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 20px;
  transition: 0.5s transform ease-in;
}

.helpline-give-card:hover {
  background-color: #fff;
  transform: scale(1.02);
  /* color: #1c304d; */
  color: red;
}

.helpline-left {
  display: flex;
  /* max-width: 200px; */
  gap: 20px;
  /* width: 200px; */
  /* background-color: red; */
  /* justify-content: space-between; */
}

.helpline-left-icon {
  background-color: #e5f6ee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.helpline-left-icon > * {
  color: #00a359;
  font-size: 60px;
  padding: 10px;
}

.helpline-left-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
}

.helpline-give-card:hover .helpline-left-text {
  color: #1c304d;
}

.helpline-caret > * {
  font-size: 30px;
  color: #000;
  cursor: pointer;
}

.helpline-give-card:hover .helpline-caret > * {
  color: #1c304d;
}

.helpline-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
}

.helpline-contact-icon > * {
  font-size: 30px;
  color: #00a359;
}

@media (min-width: 810px) {
  .helpline {
    display: flex;
    gap: 20px;
  }

  .helpline > * {
    flex: 0.5;
    /* flex-basis: 40%; */
  }

  .helpline-left-text {
    font-size: 30px;
  }
}
