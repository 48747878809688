.mobile {
  height: 8vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  z-index: 10;
  overflow: hidden;
}

.mobile-menu {
  display: flex;
  justify-content: space-between;
}

.mobile-menu-search {
  background-color: #00a359;
  border: 1px solid #00a359;
  padding: 5px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in;
  position: relative;
  z-index: 0;
}

.mobile-menu-search::after {
  content: "";
  width: 10px;
  height: 42.5px;
  background-color: #edededba;
  position: absolute;
  z-index: -2;
  transform: translate(-25px, 0px) rotate(-1deg);
  transition: 0.2s all ease-in;
  overflow: hidden;
}

.mobile-menu-search:hover::after {
  width: 20px;
  transform: translate(-35px, 0px) rotate(-1deg);
}

.mobile-menu-search:hover {
  background-color: #20704f;
}

.mobile-menu-search > * {
  font-size: 30px;
  color: #fff;
}

.mobile-menu-slider {
  border: 1px solid #ededed;
  padding: 5px;
  cursor: pointer;
}

.mobile-menu-slider > * {
  font-size: 30px;
  color: #00a359;
}

.mobile-link {
  font-size: 18px;
}

.sub-link {
  font-size: 15px;
  padding-left: 10px;
  padding-top: 15px;
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  transition: 1s all ease-in;
}

.sub-link.active {
  /* display: block; */
  visibility: visible;
  opacity: 1;
}

.mobile-nav-link-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.mobile-nav-link-cont {
  margin-bottom: 20px;
}

.ul {
  /* display: none; */
  opacity: 0;
  transition: opacity 1s ease-in;
}

.ul.active {
  /* display: block; */
  opacity: 1;
}
