.footer {
  padding: 20px;
  background-color: #193844;
  color: rgba(255, 255, 255, 1);
}

.footer-contact-location {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px;
  font-size: 14px;
}

.footer-heading {
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
}

.footer-heading::after {
  content: "";
  width: 10%;
  height: 5px;
  display: block;
  background-color: #ebf8f2;
  margin-bottom: 20px;
  margin-top: 5px;
}

.footer-two {
  margin-bottom: 20px;
}

.footer-two > * {
  margin-bottom: 10px;
  font-size: 14px;
}

.footer-three-service-cont {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 20px;
}

.footer-three-service h4 {
  font-weight: bolder;
  font-size: 16px;
}

@media (min-width: 810px) {
  .footer {
    display: flex;
    flex-direction: row;
  }

  .footer > * {
    padding: 10px;
    width: 33.33%;
  }
}
