.gallery {
  padding: 20px;
  /* background-color: #fff; */
}

.gallery h2 {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
